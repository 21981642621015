import React from 'react';
import { Link } from "react-router-dom";
import { useAuth0 } from '@auth0/auth0-react';
import LogoutButton from '../components/logoutButton'

const Dashboard = () => {
  const { user } = useAuth0();
  const { picture, email } = user;

  const awsConnectUrl = process.env.REACT_APP_AWS_CONNECT_URL;

  return (
    <div className="dashboard">
      <img src={picture} className="profile-img" alt="profile" />
      <div>Email: {email}</div>
      <Link to={awsConnectUrl}>Aws Connect</Link>
      <div><LogoutButton /></div>
    </div>
  );
};

export default Dashboard;

