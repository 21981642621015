import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { Oval } from 'react-loader-spinner';
import { useAuth0 } from '@auth0/auth0-react';
import ProtectedRoute from './components/protectedRoute';
import common from './constants/common';
import Layout from './components/layout';
import Dashboard from './pages/dashboard';
import NotFound from './pages/notFound';
import './App.css';

function App() {
  const { isLoading } = useAuth0();

  if (isLoading) {
    return (
      <div className="loader fixed" >
        <Oval color={common.loaderColor} />
      </div>
    );
  }

  return (
    <Layout>
      <Routes>
        <Route path='*' element={<NotFound />} />
        <Route
          exact
          path={common.path.dashboard}
          element={<ProtectedRoute path={common.path.dashboard} component={Dashboard} />}
        />
        <Route exact path={common.path.notFound} element={<NotFound />} />
      </Routes>
    </Layout>
  );
}

export default App;
