import React from 'react';
import PropTypes from 'prop-types';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Layout = ({ children }) => (
  <div>
    <div className="content">{children}</div>
    <ToastContainer />
  </div>
);

Layout.propTypes = {
  children: PropTypes.object,
};

export default Layout;
