import React from 'react';
import PropTypes from 'prop-types';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { Oval } from 'react-loader-spinner';
import common from '../constants/common';

const ProtectedRoute = ({ component: Component, ...props }) => {
  const Cp = withAuthenticationRequired(Component, {
    onRedirecting: () => {
      return (
        <div className="loader fixed" >
          <Oval color={common.loaderColor} />
        </div>
      );
    }
  });
  return <Cp {...props} />
};

ProtectedRoute.propTypes = {
  component: PropTypes.func.isRequired,
};

export default ProtectedRoute;
