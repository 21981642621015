import React from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Auth0Provider } from '@auth0/auth0-react';

const AuthProviderWithNavigate = ({ children }) => {
  const domain = process.env.REACT_APP_AUTH0_DOMAIN;
  const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
  const connection = process.env.REACT_APP_AUTH0_CONNECTION
  const awsConnectDomain = process.env.REACT_APP_AWS_CONNECT_URL;

  const navigate = useNavigate();

  const onRedirectCallback = (appState) => {
    navigate(appState?.returnTo || window.location.pathname);
  };

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      redirectUri={awsConnectDomain}
      onRedirectCallback={onRedirectCallback}
      connection={connection}
    >
      {children}
    </Auth0Provider>
  );
};

AuthProviderWithNavigate.propTypes = {
  children: PropTypes.object,
};

export default AuthProviderWithNavigate;

